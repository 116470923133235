import { useCallback } from "react";
import { useAppMode } from "../contexts/AppContext";
import { useTransition } from "../contexts/TransitionContext";
import { disableFullscreen } from "../config";

export function useFullScreen(callback: string | (() => void | undefined)) {
	const appMode = useAppMode();
	const transit = useTransition();
	return useCallback(() => {
		if (
			!document.fullscreenElement &&
			appMode === "kiosk" &&
			!disableFullscreen
		)
			document.documentElement.requestFullscreen();
		if (typeof callback === "string") return transit(callback);
		return callback?.();
	}, [appMode, callback, transit]);
}
