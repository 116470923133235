import { useDebounce } from "@uidotdev/usehooks";
import { HTMLProps, useMemo, useRef } from "react";

export type ValidityPriority = Partial<Record<keyof ValidityState, number>>;
export type ValidityMessages = Partial<
	Record<keyof ValidityState, string | ((input: HTMLInputElement) => string)>
>;

const DEFAULT_PRIORITY: ValidityPriority = {
	tooShort: 1,
	tooLong: 2,
	patternMismatch: 3,
	valueMissing: 4,
	typeMismatch: 5,
};

const DEFAULT_MESSAGES: ValidityMessages = {
	valueMissing: "Please fill out this field.",
	typeMismatch: "Please enter a valid value.",
	tooShort: (input: HTMLInputElement) =>
		`Please lengthen this text to ${input.minLength} characters (you are currently using ${input.value.length} characters).`,
	tooLong: (input: HTMLInputElement) =>
		`Please shorten this text to ${input.maxLength} characters (you are currently using ${input.value.length} characters).`,
	patternMismatch: "Please enter a valid value.",
};

const getErrorPriority = (
	validity: ValidityState,
	validityPriority: ValidityPriority
): [string, number][] => {
	return (Object.entries(validityPriority) as [string, number][])
		.filter(([key]) => validity[key as keyof ValidityState])
		.sort((a, b) => a[1] - b[1]);
};

export function useInputValidation(
	value: string,
	props: Pick<
		HTMLProps<HTMLInputElement>,
		"pattern" | "maxLength" | "minLength" | "required" | "type"
	>,
	options?: {
		priority?: ValidityPriority;
		messages?: ValidityMessages;
	}
) {
	const { priority = DEFAULT_PRIORITY, messages } = options ?? {};
	const mergedMessages = useMemo(
		() => ({ ...DEFAULT_MESSAGES, ...messages }),
		[messages]
	);
	const debouncedValue = useDebounce(value, 250);
	const inialValue = useRef(value);
	const isDirty = useRef(false);

	return useMemo(() => {
		const input = document.createElement("input");
		input.type = props.type ?? "text";
		input.value = debouncedValue;
		for (const validation in props) {
			const key = validation as keyof typeof props;
			if (key === "type") continue;
			if (props[key] === undefined) continue;
			input.setAttribute(key, props[key] as string);
		}

		if (!isDirty.current && inialValue.current !== debouncedValue) {
			isDirty.current = true;
		}

		const valid = input.checkValidity();
		if (valid)
			return {
				valid,
				message: undefined,
				dirty: isDirty.current,
			};
		const { validity, validationMessage } = input;
		//NOTE: Workaround for Chrome silently failing to validate minLength and maxLength
		const tooLong = !!props.maxLength && input.value.length > props.maxLength;
		const tooShort = !!props.minLength && input.value.length < props.minLength;

		//WARN: Have to manually add other values from ValidityState because its properties are not enumerable
		// cannot use {...validity, tooLong, tooShort}
		const prioritizedErrors = getErrorPriority(
			{
				tooLong,
				tooShort,
				patternMismatch: validity.patternMismatch,
				valueMissing: validity.valueMissing,
				typeMismatch: validity.typeMismatch,
			} as ValidityState,
			priority
		);
		let message = validationMessage;
		if (prioritizedErrors.length > 0 && mergedMessages) {
			const [errorType] = prioritizedErrors[0];
			const customMessage = mergedMessages[errorType as keyof ValidityState];
			message =
				typeof customMessage === "function"
					? customMessage(input)
					: customMessage || validationMessage;
		}

		return { valid, message, dirty: isDirty.current };
	}, [debouncedValue, mergedMessages, priority, props]);
}
