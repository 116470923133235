import { useCallback, useState } from "react";
import Controls from "./Controls";
import styles from "./Text.module.scss";
import Layout from "./shared/Layout";
import TextInput from "./shared/TextInput";
import { useQuestions } from "../../contexts/QuestionContext";
import useMatchMedia from "../../utils/useMatchMedia";
import { useAppMode } from "../../contexts/AppContext";
import {
	useInputValidation,
	ValidityMessages,
	ValidityPriority,
} from "../../utils/useInputValidation";

type TextProps = {
	index: number;
	type: string;
	label: string;
	suggestions?: string[];
	suggestionsLabel?: string;
	validityPriority?: ValidityPriority;
	validityMessages?: ValidityMessages;
};
export default function Input({
	type,
	label,
	index,
	suggestions,
	suggestionsLabel,
	validityPriority,
	validityMessages,
	...inputProps
}: TextProps) {
	const { answers, saveAnswer } = useQuestions();
	const [value, setValue] = useState<string>(answers[index] ?? "");
	const isLargeScreen = useMatchMedia("(min-width: 1280px)");
	const appMode = useAppMode();
	const onExampleSelect = useCallback((value: string) => {
		setValue(value);
	}, []);
	const { valid, message, dirty } = useInputValidation(
		value,
		{ type, required: true, ...inputProps },
		{ priority: validityPriority, messages: validityMessages }
	);
	const onInput = useCallback((value: string) => {
		setValue(value);
	}, []);

	return (
		<Layout
			textInput={true}
			label={label}
			examples={isLargeScreen ? suggestions : []}
			examplesLabel={suggestionsLabel}
			onExampleSelect={onExampleSelect}
			controls={
				<Controls
					className={styles.controls}
					canProceed={valid}
					onNext={(next) => {
						saveAnswer(index, value);
						next();
					}}
					examples={!isLargeScreen ? suggestions : []}
					examplesLabel={suggestionsLabel}
					onExampleSelect={onExampleSelect}
				/>
			}
		>
			<TextInput
				{...inputProps}
				type={type}
				value={value}
				onInput={onInput}
				errorMessage={dirty ? message : undefined}
				onScreenKeyboardId={
					appMode === "kiosk" ? `textInput-${index}` : undefined
				}
			/>
		</Layout>
	);
}
