class Capture {
	imageCapture = null;
	stream = null;
	DEBUG_VIDEO = false;
	DEBUG_CAPTURE = false;

	constructor(debug = false) {
		this.capture = null;
		this.video = document.createElement("video");
		this.inited = false;
		this.DEBUG_VIDEO = debug;
		this.DEBUG_CAPTURE = debug;
	}

	init = async () => {
		if (this.inited) return;
		// make sure it's 4:3 ratio
		const CANVAS_WIDTH = 1440;
		const CANVAS_HEIGHT = 1080;
		const constraints = {
			audio: false,
			video: {
				width: CANVAS_WIDTH,
				height: CANVAS_HEIGHT,
			},
		};

		const stream = await navigator.mediaDevices.getUserMedia(constraints);
		this.stream = stream;
		this.video.autoplay = true;
		this.video.srcObject = stream;

		if (this.DEBUG_VIDEO) {
			document.body.appendChild(this.video);
			this.video.style.position = "fixed";
			this.video.style.top = "0px";
			this.video.style.left = "0px";
			this.video.style.width = "100%";
			this.video.style.height = "100%";
			this.video.style.zIndex = "1000";
			this.video.style.opacity = "0.2";
			this.video.style.pointerEvents = "none";
		}

		const track = stream.getVideoTracks()[0];
		const capture = new ImageCapture(track);
		this.imageCapture = capture;
		this.inited = true;
	};

	getStream = () => {
		return this.stream;
	};

	getVideo = () => {
		return this.video;
	};

	captureImage = async (name) => {
		if (!this.imageCapture) throw new Error(`imageCapture is undefined`);
		return this.imageCapture.takePhoto().then((blob) => {
			if (this.DEBUG_CAPTURE) {
				const url = URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = name + `.jpg`;
				a.click();
			}

			return blob;
		});
	};
}

export default Capture;
