import { FormEventHandler, useCallback, useState } from "react";
import Controls from "./Controls";
import styles from "./ZipCode.module.scss";
import Checkbox from "./shared/Checkbox";
import Layout from "./shared/Layout";
import TextInput from "./shared/TextInput";
import { useQuestions } from "../../contexts/QuestionContext";
import {
	useInputValidation,
	ValidityMessages,
	ValidityPriority,
} from "../../utils/useInputValidation";
import { useAppMode } from "../../contexts/AppContext";

type ZipCodeProps = {
	label: string;
	outsideLabel: string;
	outsideValueAlias: string;
	outsideValue: string;
	index: number;
	validityPriority?: ValidityPriority;
	validityMessages?: ValidityMessages;
};
export default function ZipCode({
	label,
	index,
	outsideLabel,
	outsideValueAlias,
	outsideValue,
	validityPriority,
	validityMessages,
	...inputProps
}: ZipCodeProps) {
	const { answers, saveAnswer } = useQuestions();
	const [value, setValue] = useState<string>(answers[index] ?? "");
	const [checked, setChecked] = useState(answers[index] === outsideValueAlias);

	const { valid, message, dirty } = useInputValidation(
		value,
		{
			type: "text",
			required: true,
			pattern: !checked ? "[0-9]{5}" : undefined,
			...inputProps,
		},
		{ priority: validityPriority, messages: validityMessages }
	);
	const onInput = useCallback((value: string) => {
		setValue(value);
	}, []);
	const onCheckboxChange = useCallback<FormEventHandler<HTMLInputElement>>(
		(event) => {
			setChecked(event.currentTarget.checked);
			setValue(event.currentTarget.checked ? outsideValueAlias : "");
		},
		[outsideValueAlias]
	);
	const appMode = useAppMode();

	const mergedInputProps = {
		validityMessages: {
			patternMismatch: "Please enter a valid ZIP code (eg. 90210)",
		},
		...inputProps,
	};

	return (
		<Layout
			textInput={true}
			label={label}
			controls={
				<Controls
					className={styles.controls}
					canProceed={valid || checked}
					onNext={(next) => {
						saveAnswer(index, value);
						next();
					}}
				/>
			}
		>
			<TextInput
				{...mergedInputProps}
				type="text"
				disabled={value === outsideValueAlias}
				value={value}
				onInput={onInput}
				errorMessage={dirty ? message : undefined}
				onScreenKeyboardId={
					appMode === "kiosk" ? `textInput-${index}` : undefined
				}
			/>
			<Checkbox
				className={appMode === "kiosk" ? styles.checkbox : undefined}
				label={outsideLabel}
				value={outsideValueAlias}
				checked={checked}
				onChange={onCheckboxChange}
			/>
		</Layout>
	);
}
