import "./App.css";
import "./styles/global.scss";

import { Route, Routes } from "react-router-dom";

// Handlers
import { AppHandler } from "./contexts/AppContext";
import { CaptureHandler } from "./contexts/CaptureContext";
import { FaceHandler } from "./contexts/FaceContext";
import { TransitionHandler } from "./contexts/TransitionContext";

import Age from "./pages/Age";
import Confirmation from "./pages/Confirmation";
import KioskOrMobile from "./pages/KioskOrMobile";
import Questionnaire from "./pages/Questionnaire";
import Start from "./pages/Start";
import Welcome from "./pages/Welcome";
import MobileLanding from "./pages/MobileLanding";
import NotFound from "./pages/NotFound";
import KioskRegister from "./pages/KioskRegister";
import HyperCinema from "./pages/HyperCinema";
import { PassScanListener } from "./components/PassScanListener";
import { CFHOF_KIOSK_ROUTE, CFHOF_MOBILE_ROUTE } from "./config";
import InactivityWrapper from "./components/InactivitiyWrapper";
import Instruction from "./pages/Instruction";
import Capture from "./pages/Capture";
import MobileDeparture from "./pages/MobileDeparture";
import { OnScreenKeyboardHandler } from "./contexts/OnScreenKeyboardContext";

function App() {
	return (
		<div className="App">
			<AppHandler
				publicRender={() => (
					<TransitionHandler>
						<Routes>
							<Route path={CFHOF_KIOSK_ROUTE} element={<KioskRegister />} />
							<Route path={CFHOF_MOBILE_ROUTE}>
								<Route path="" element={<MobileLanding />} />
								<Route path="questionnaire" element={<Questionnaire />} />
								<Route path="departure" element={<MobileDeparture />} />
							</Route>
							<Route path="/" element={<HyperCinema />} />
							<Route path="/*" element={<NotFound />} />
						</Routes>
					</TransitionHandler>
				)}
				kioskRender={(kioskCredential) => (
					<CaptureHandler>
						<FaceHandler>
							<TransitionHandler>
								{!!kioskCredential.kioskId && (
									<PassScanListener
										token={kioskCredential.apiToken}
										venueId={parseInt(kioskCredential.venueId, 10)}
										kioskId={parseInt(kioskCredential.kioskId, 10)}
									/>
								)}
								<Routes>
									<Route path={CFHOF_KIOSK_ROUTE}>
										<Route path="" element={<Welcome />} />
										<Route path="" element={<InactivityWrapper />}>
											<Route path="instruction" element={<Instruction />} />
											<Route path="age" element={<Age />} />
											<Route path="start" element={<Start />} />
											<Route path="capture" element={<Capture />} />
											<Route
												path="kiosk-or-mobile"
												element={<KioskOrMobile />}
											/>
											<Route
												path="questionnaire"
												element={
													<OnScreenKeyboardHandler>
														<Questionnaire />{" "}
													</OnScreenKeyboardHandler>
												}
											/>
											<Route path="confirmation" element={<Confirmation />} />
										</Route>
									</Route>
									<Route path="/" element={<HyperCinema />} />
									<Route path="/*" element={<NotFound />} />
								</Routes>
							</TransitionHandler>
						</FaceHandler>
					</CaptureHandler>
				)}
			/>
		</div>
	);
}

export default App;
