import SimpleKeyboard, {
	KeyboardOptions,
	SimpleKeyboard as Keyboard,
} from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import styles from "./OnScreenKeyboard.module.scss";
import cx from "classnames";

export { type Keyboard };
export type OnKeyboardPress = NonNullable<KeyboardOptions["onKeyPress"]>;
export type OnKeyboardInit = NonNullable<KeyboardOptions["onInit"]>;
export type OnKeyboardValueChange = NonNullable<KeyboardOptions["onChange"]>;
export type OnKeyboardVisibilityChange = (value: boolean) => void;
export type KeyboardLayout = "default" | "shift" | "email";

type OnScreenKeyboardProps = {
	onKeyboardInit?: OnKeyboardInit;
	onKeyboardValueChange?: OnKeyboardValueChange;
	onKeyboardVisibilityChange?: OnKeyboardVisibilityChange;
	onKeyboardPress?: OnKeyboardPress;
	keyboardLayout?: KeyboardLayout;
	keyboardVisibility?: boolean;
	inputName?: string;
	maxLength?: number;
};

export default function OnScreenKeyboard({
	onKeyboardInit,
	onKeyboardValueChange,
	onKeyboardVisibilityChange,
	onKeyboardPress,
	keyboardLayout,
	keyboardVisibility,
	maxLength,
}: OnScreenKeyboardProps) {
	return (
		<>
			<div
				className={cx(styles.backdrop, {
					[styles.clickThrough]: !keyboardVisibility,
				})}
				onClick={() => onKeyboardVisibilityChange?.(false)}
			/>
			<div
				className={cx(styles.container, {
					[styles.visible]: keyboardVisibility,
				})}
			>
				<SimpleKeyboard
					maxLength={maxLength}
					display={{
						"{bksp}": "delete",
						"{enter}": "enter",
						"{shift}": "shift",
						"{space}": "space",
					}}
					buttonTheme={[
						{
							class: styles.enterKey,
							buttons: "{enter}",
						},
						{
							class: styles.backspaceKey,
							buttons: "{bksp}",
						},
						{
							class: styles.spaceKey,
							buttons: "{space}",
						},
						{
							class:
								keyboardLayout === "shift"
									? styles.shiftKeyOn
									: styles.shiftKey,
							buttons: "{shift}",
						},
					]}
					layout={{
						default: [
							"1 2 3 4 5 6 7 8 9 0 - _ + {bksp}",
							"q w e r t y u i o p ! @",
							"a s d f g h j k l ; : '",
							"{shift} z x c v b n m , . .com",
							"{space} {enter}",
						],
						shift: [
							"1 2 3 4 5 6 7 8 9 0 - _ + {bksp}",
							"Q W E R T Y U I O P ! @",
							"A S D F G H J K L ; : '",
							"{shift} Z X C V B N M , . .com",
							"{space} {enter}",
						],
					}}
					layoutName={keyboardLayout}
					baseClass={styles.keyboard}
					onInit={onKeyboardInit}
					onChange={onKeyboardValueChange}
					onKeyPress={onKeyboardPress}
				/>
			</div>
		</>
	);
}
