import cx from "classnames";
import { HTMLProps, useEffect, useRef } from "react";
import styles from "./TextInput.module.scss";
import { useAppIdle } from "../../../contexts/AppContext";
import { useOnScreenKeyboard } from "../../../contexts/OnScreenKeyboardContext";

export type TextInputProps = Pick<
	HTMLProps<HTMLInputElement>,
	"type" | "disabled" | "placeholder" | "maxLength"
> & {
	value: string | number;
	onInput?: (value: string) => void;
	onScreenKeyboardId?: string;
	errorMessage?: string;
};

export default function TextInput({
	onScreenKeyboardId,
	errorMessage,
	...inputProps
}: TextInputProps) {
	const useCustomKeyboard = !!onScreenKeyboardId;
	return (
		<>
			{useCustomKeyboard ? (
				<OnScreenKeyboardInput
					{...inputProps}
					errorMessage={errorMessage}
					onScreenKeyboardId={onScreenKeyboardId}
				/>
			) : (
				<SystemKeyboardInput {...inputProps} errorMessage={errorMessage} />
			)}
			<div className={cx(styles.message, { [styles.error]: !!errorMessage })}>
				{!!errorMessage && <p>{errorMessage}</p>}
			</div>
		</>
	);
}

export type SystemKeyboardInputProps = Pick<
	TextInputProps,
	"onInput" | "type" | "disabled" | "value" | "placeholder" | "errorMessage"
>;

function SystemKeyboardInput({
	type,
	disabled,
	value,
	placeholder,
	onInput,
	errorMessage,
}: SystemKeyboardInputProps) {
	const input = useRef<HTMLInputElement>(null);
	const isIdle = useAppIdle();
	if (isIdle) input.current?.blur();

	useEffect(() => {
		const id = setTimeout(() => {
			input.current?.focus();
		}, 250);
		return () => clearTimeout(id);
	}, []);

	return (
		<input
			ref={input}
			type={type}
			autoComplete="off"
			autoCorrect="off"
			spellCheck={false}
			value={value}
			onInput={(event) => {
				onInput?.(event.currentTarget.value);
			}}
			placeholder={placeholder}
			className={cx(styles.input, {
				[styles.error]: !!errorMessage,
				[styles.valid]: !errorMessage,
			})}
			disabled={disabled}
		/>
	);
}

export type OnScreenKeyboardInputProps = Pick<
	TextInputProps,
	| "onInput"
	| "type"
	| "disabled"
	| "value"
	| "placeholder"
	| "errorMessage"
	| "onScreenKeyboardId"
	| "maxLength"
>;

function OnScreenKeyboardInput({
	type,
	disabled,
	value,
	placeholder,
	onInput,
	onScreenKeyboardId,
	errorMessage,
	...props
}: OnScreenKeyboardInputProps) {
	const {
		setKeyboardVisibility,
		onInputValueChange,
		onInputNameChange,
		onInputTypeChange,
		onInputMaxLengthChange,
	} = useOnScreenKeyboard();
	const input = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const id = setTimeout(() => {
			input.current?.focus();
		}, 250);
		return () => clearTimeout(id);
	}, []);

	useEffect(() => {
		return () => {
			setKeyboardVisibility(false);
		};
	}, [setKeyboardVisibility]);

	useEffect(() => {
		if (!onScreenKeyboardId) return;
		const listener = ((event: CustomEvent<string>) => {
			onInput?.(event.detail);
		}) as EventListener;

		window.addEventListener(onScreenKeyboardId, listener);
		return () => {
			window.removeEventListener(onScreenKeyboardId, listener);
		};
	}, [onInput, onScreenKeyboardId]);

	useEffect(() => {
		onInputNameChange(onScreenKeyboardId);
		return () => {
			onInputNameChange();
		};
	}, [onScreenKeyboardId, onInputNameChange]);

	useEffect(() => {
		onInputValueChange(value.toString());
	}, [value, onInputValueChange]);

	useEffect(() => {
		onInputTypeChange(type);
		return () => {
			onInputTypeChange();
		};
	}, [type, onInputTypeChange]);

	useEffect(() => {
		onInputMaxLengthChange(Math.min(props.maxLength ?? 25, 25));
		return () => {
			onInputMaxLengthChange();
		};
	}, [onInputMaxLengthChange, props.maxLength]);

	return (
		<input
			ref={input}
			type={type}
			value={value}
			readOnly={true}
			onFocus={() => setKeyboardVisibility(true)}
			placeholder={placeholder}
			className={cx(styles.input, {
				[styles.error]: !!errorMessage,
				[styles.valid]: !errorMessage,
			})}
			disabled={disabled}
		/>
	);
}
