import {
	createContext,
	PropsWithChildren,
	useState,
	useContext,
	useCallback,
} from "react";
import OnScreenKeyboard, {
	Keyboard,
	KeyboardLayout,
	OnKeyboardInit,
	OnKeyboardPress,
	OnKeyboardValueChange,
} from "../components/OnScreenKeyboard";

type OnScreenKeyboardInterface = {
	onInputNameChange: (value?: string | undefined) => void;
	onInputValueChange: (value: string) => void;
	onInputTypeChange: (value?: string | undefined) => void;
	onInputMaxLengthChange: (value?: number | undefined) => void;
	setKeyboardVisibility: (value: boolean) => void;
};

const OnScreenKeyboardContext = createContext<OnScreenKeyboardInterface>(
	{} as OnScreenKeyboardInterface
);

export default OnScreenKeyboardContext;

type OnScreenKeyboardProps = {} & PropsWithChildren;

export function OnScreenKeyboardHandler({ children }: OnScreenKeyboardProps) {
	const [keyboard, setKeyboard] = useState<Keyboard>();
	const [keyboardVisible, setKeyboardVisible] = useState(false);
	const [keyboardLayout, setKeyboardLayout] = useState<KeyboardLayout>("shift");
	const [inputName, setInputName] = useState<string>("default");
	const [inputType, setInputType] = useState<string>("text");
	const [inputMaxLength, setInputMaxLength] = useState<number | undefined>();

	const onKeyboardInit = useCallback<OnKeyboardInit>((value) => {
		setKeyboard(value);
	}, []);

	const onKeyboardValueChange = useCallback<OnKeyboardValueChange>(
		(value) => {
			const event = new CustomEvent(inputName, { detail: value });
			window.dispatchEvent(event);
		},
		[inputName]
	);

	const onKeyboardPress = useCallback<OnKeyboardPress>((button) => {
		if (button === "{enter}") {
			window.dispatchEvent(new KeyboardEvent("keydown", { key: "Enter" }));
		}

		if (button === "{shift}") {
			setKeyboardLayout((prev) => (prev === "shift" ? "default" : "shift"));
		}
	}, []);

	const onInputNameChange = useCallback<
		OnScreenKeyboardInterface["onInputNameChange"]
	>((value) => {
		setInputName(value ?? "default");
	}, []);

	const onInputValueChange = useCallback<
		OnScreenKeyboardInterface["onInputValueChange"]
	>(
		(value) => {
			keyboard?.setInput(value);
			setKeyboardLayout(
				inputType === "email" ? "default" : value ? "default" : "shift"
			);
		},
		[inputType, keyboard]
	);

	const onInputTypeChange = useCallback<
		OnScreenKeyboardInterface["onInputTypeChange"]
	>((value) => {
		setInputType(value ?? "text");
	}, []);

	const setKeyboardVisibility = useCallback<
		OnScreenKeyboardInterface["setKeyboardVisibility"]
	>((value) => {
		setKeyboardVisible(value);
	}, []);

	const onInputMaxLengthChange = useCallback<
		OnScreenKeyboardInterface["onInputMaxLengthChange"]
	>((value) => {
		setInputMaxLength(value);
	}, []);

	return (
		<OnScreenKeyboardContext.Provider
			value={{
				onInputNameChange,
				onInputValueChange,
				onInputTypeChange,
				onInputMaxLengthChange,
				setKeyboardVisibility,
			}}
		>
			{children}
			<OnScreenKeyboard
				onKeyboardInit={onKeyboardInit}
				onKeyboardValueChange={onKeyboardValueChange}
				onKeyboardVisibilityChange={setKeyboardVisibility}
				onKeyboardPress={onKeyboardPress}
				keyboardLayout={keyboardLayout}
				keyboardVisibility={keyboardVisible}
				maxLength={inputMaxLength}
			/>
		</OnScreenKeyboardContext.Provider>
	);
}

export function useOnScreenKeyboard() {
	const context = useContext(OnScreenKeyboardContext);
	return context;
}
