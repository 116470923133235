import { HTMLProps, useId } from "react";
import styles from "./Checkbox.module.scss";
import cx from "classnames";

export type CheckboxProps = Pick<
	HTMLProps<HTMLInputElement>,
	"checked" | "onChange" | "className"
> & {
	label: string;
	value: string;
};
export default function Checkbox({
	label,
	className,
	...inputProps
}: CheckboxProps) {
	const checkboxId = useId();

	return (
		<div className={cx(styles.checkbox, className)}>
			<input id={checkboxId} {...inputProps} type="checkbox" />
			<label htmlFor={checkboxId} className={styles.checkboxLabel}>
				<span className={styles.checkboxLabel_a}></span>
				<span className={styles.checkboxLabel_b}>{label}</span>
			</label>
		</div>
	);
}
